import React from "react";
import "./App.css";

const About = () => {
  return (
    <div className="legal-container">
      <div className="legal-header">
        <h1>Terms of Service</h1>
      </div>
      <div className="legal-body">
        <p>
          Welcome to Educade. Please read these Terms of Service ("Terms")
          carefully before using our website/service. By accessing or using our
          services, you agree to abide by these Terms.
          <br></br>
          <br></br>
          Acceptance of Terms: By accessing or using our website or services,
          you confirm that you have read, understood, and agree to be bound by
          these Terms and our Privacy Policy.
          <br></br>
          <br></br>
          Eligibility: By using our services, you represent and warrant that you
          are at least [e.g., "13"] years of age or have obtained parental or
          guardian consent.
          <br></br>
          <br></br>
          Use of Our Services: You agree to use our services for lawful purposes
          only and in a manner consistent with any and all applicable local,
          national, and international laws and regulations.
          <br></br>
          <br></br>
          Content and Intellectual Property: All content on our website,
          including text, graphics, logos, and images, is our property or the
          property of our licensors and is protected by copyright and other
          laws. Unauthorized use of any content may violate copyright,
          trademark, and other laws.
          <br></br>
          <br></br>
          Account Responsibilities: If our services require an account, you are
          responsible for maintaining the confidentiality of your account and
          password and for all activities under your account. You agree to
          notify us immediately of any unauthorized use of your account.
          <br></br>
          <br></br>
          Limitation of Liability: We and our affiliates, partners, or licensors
          shall not be liable for any indirect, incidental, special,
          consequential, or exemplary damages resulting from your use or
          inability to use our services or any content therein.
          <br></br>
          <br></br>
          Termination: We reserve the right to terminate or suspend access to
          our services, without notice, for conduct we believe violates these
          Terms or is harmful to other users, us, or third parties, or for any
          other reason.
          <br></br>
          <br></br>
          Changes to Terms: We may modify these Terms at any time. Changes will
          be reflected by the "Last Updated" date at the top. Continued use of
          our services after any changes constitutes your acceptance of the new
          Terms.
          <br></br>
          <br></br>
          Governing Law: These Terms are governed by the laws of Ireland,
          without respect to its conflict of laws principles.
          <br></br>
          <br></br>
          Contact: For any questions or concerns regarding these Terms, please
          contact us at info@educade.ie
        </p>
        {/* Add any additional content or images here */}
      </div>
      <div className="legal-header">
        <h1>
          <br></br>
          Privacy Policy{" "}
        </h1>
      </div>
      <div className="legal-body">
        <p>
          This privacy policy outlines the types of personal information we
          collect, how we use it, and the steps we take to protect it.
          <br></br>
          <br></br>
          Information We Collect: Automatically Collected Data: When you visit
          our site, we may collect data about your device, including your IP
          address, browser type, operating system, and other technical
          information.
          <br></br>
          <br></br>
          User-Provided Data: We may collect personal data you provide, such as
          your name, email address, and other relevant details when you sign up,
          interact, or transact on our website.
          <br></br>
          <br></br>
          How We Use Your Information: To improve our website and provide you
          with a better user experience. To communicate with you about updates,
          promotions, or news. To analyze usage and trends to better understand
          our users.
          <br></br>
          <br></br>
          Cookies and Local Storage: Our website may use local storage and
          cookies to enhance user experience. These are small files stored on
          your device. You can choose to disable cookies through your browser
          settings.
          <br></br>
          <br></br>
          Protection of Your Data: We employ various security measures to
          protect your information. However, no method of transmission or
          storage is entirely secure, and while we strive to protect your data,
          we cannot guarantee its absolute security.
          <br></br>
          <br></br>
          Third-Party Links: Our website may contain links to other sites. Once
          you click on these links and leave our site, we do not have control
          over the external site. We are not responsible for the content or
          privacy practices of these external sites.
          <br></br>
          <br></br>
          Data Rights: You may request access to, modification of, or deletion
          of your personal data we hold. Please contact us at @gmail.com for
          such requests.
          <br></br>
          <br></br>
          Updates to this Policy: We may update this privacy policy
          periodically. Changes will be reflected by the "Last Updated" date at
          the top. We encourage users to regularly check this policy for
          updates.
        </p>
      </div>
    </div>
  );
};

export default About;

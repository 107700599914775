export const compliments = [
  "Savage answer!",
  "Fair play to ya!",
  "Answer goals!",
  "Mastermind alert!",
  "Pure class!",
  "G'wan ya good thing!",
  "Pure genius, like Tayto in a sandwich!",
  "Flying it!",
  "You're on the ball!",
  "Not a bother to ya!",
  "Brainiac on board!",
  "You're sucking diesel now!",
  "Spitting facts!",
  "You're on a roll, like a rasher!",
  "Bualadh bos!",
  "Dropping wisdom bombs!",
  "Genius on the loose!",
  "Clever clogs, aren't ya?",
];

export const insults = [
  // "That was... imaginative!",
];

export const HIGH_SCORE_COMMENTS = [
  "You didn't just raise the bar, you launched it into orbit!",
  "Einstein, is that you?! Your score is relatively high!",
  "Turn down the brightness, your brilliance is blinding!",
  "Someone's got cheat codes for knowledge!",
  "Who needs Google? We've got you!",
  "The mitochondria is the powerhouse of the cell and you're the powerhouse of this quiz!",
  "Forget A+, you're in a league of your own!",
  "Straight outta Brainville!",
  "That performance deserves a mic drop!",
  "You just turned this quiz into your highlight reel!",
];

export const MID_SCORE_COMMENTS = [
  "Halfway to genius, halfway to 'did you even read the textbook?'!",
  "You're like Schrödinger's student. In a state of both right and wrong!",
  "Right on the brink of greatness! Just a few tweaks needed.",
  "Hitting that sweet spot between newbie and know-it-all!",
  "Not quite a mastermind, but definitely not clueless!",
  "If knowledge was a slider, you'd be sitting comfortably in the middle!",
  "Like a coin toss, sometimes heads, sometimes tails, always valuable!",
  "In the world of scores, you're the reliable middle child!",
  "You're like a playlist on shuffle - a mix of hits and misses!",
  "Flirting with perfection but also making friends with mistakes!",
];

export const LOW_SCORE_COMMENTS = [
  "Your score's so low it's reached the Mariana Trench!",
  "Well, no one's perfect! Or even close, in this case...",
  "Well, that was... something.",
  "Did you use a random number generator for your answers?",
  "It's okay, even Einstein didn't know everything... but he probably knew a bit more than this.",
  "Even a broken clock is right twice a day, but these answers... oh dear.",
  "It's a good thing we're not playing for high stakes!",
  "Maybe your strategy was to see how low you could go?",
  "Maybe today's just your off day? There's always tomorrow!",
  "Ever heard of beginner's luck? It'll come... eventually.",
  "Keep calm and... maybe hit the books a bit more?",
  "Aiming for reverse psychology with that score, are you?",
  "It's not about the fall, but how you bounce back!",
];

export const BAD_WORDS = [
  "fuk",
  "puss",
  "pus",
  "ass",
  "retard",
  "nig",
  "nij",
  "fat",
  "bitch",
  "fuc",
  "cok",
  "coc",
  "dic",
  "dik",
  "penis",
  "vag",
  "wank",
  "shit",
  "piss",
  "blow",
  "bj",
  "job",
  "crap",
  "69",
  "sex",
  "black",
  "fag",
  "hole",
  "gay",
  "lesb",
  "lez",
  "rape",
  "assault",
  "arse",
  "devil",
  "demon",
  "suck",
  "tits",
  "boo",
  "breas",
  "porn",
  "anal",
  "lick",
  "nipple",
];
